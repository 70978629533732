import { Component, Prop } from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';

@Component
export default class MHParksBtnComponent extends VueWrapper {
    @Prop({type: String, default: 'primary'})
    public readonly color!: string;
    get blockPRop() {
        return this.$attrs.block ?? this.CoreSrv.block;
    }
}