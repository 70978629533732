import { render, staticRenderFns } from "./base-checkbox.vue?vue&type=template&id=3cb09e5a&"
import script from "./base-checkbox.ts?vue&type=script&lang=ts&"
export * from "./base-checkbox.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports