import { render, staticRenderFns } from "./mh-parks-btn.vue?vue&type=template&id=54f560c7&scoped=true&"
import script from "./mh-parks-btn.ts?vue&type=script&lang=ts&"
export * from "./mh-parks-btn.ts?vue&type=script&lang=ts&"
import style0 from "./mh-parks-btn.vue?vue&type=style&index=0&id=54f560c7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f560c7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseBtn: require('C:/agent/_work/4/s/components/vuetify/base-btn/base-btn.vue').default})
