import { render, staticRenderFns } from "./index-fourth-view.component.vue?vue&type=template&id=711d1128&scoped=true&"
import script from "./index-fourth-view.component.ts?vue&type=script&lang=ts&"
export * from "./index-fourth-view.component.ts?vue&type=script&lang=ts&"
import style0 from "./index-fourth-view.component.vue?vue&type=style&index=0&id=711d1128&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "711d1128",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InfoCard: require('C:/agent/_work/4/s/components/shared/info-card/info-card.vue').default,AdvertiseCard: require('C:/agent/_work/4/s/components/shared/advertise-card/advertise-card.vue').default,SignupCard: require('C:/agent/_work/4/s/components/shared/signup-card/signup-card.vue').default})
