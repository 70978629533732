import {Component, Prop} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';
import BaseFormComponent from '~/components/vuetify/base-form/base-form';
import { AdsApi, ContactSellerModel } from '~/sdk';

@Component
export default class ContactSellerComponent extends VueWrapper {
    public $refs!:{
        contactSellerForm:BaseFormComponent
    }

    @Prop({type: String, required: true})
    public readonly adId!: string;
    
    public sellerContact :ContactSellerModel = new ContactSellerModel();
    public ContactSeller() {
        this.sellerContact.AdId = this.adId;
        this.LoaderSrv.showFullScreenLoader();
        new AdsApi()
            .ContactSeller(this.sellerContact)
            .subscribe(({Data, Status}) => {
                if (Status) {
                    this.AlertSrv.show('success', 'Your message has been sent to the Seller.');
                    this.sellerContact = new ContactSellerModel();
                    this.$refs.contactSellerForm?.reset();
                    this.CoreSrv.dialog.contactSeller = false;
                    this.sellerContact = new ContactSellerModel();
                } else {
                    this.AlertSrv.show('error', 'Something went wrong');
                }
            })
            .add(() => {
                this.LoaderSrv.hideFullScreenLoader();
            });
    }
    public sendMoreInfo() {
        this.sellerContact.SendNotification ? (this.sellerContact.Message = 'Please send me more information' ) : (this.sellerContact.Message = '');
    }
}
