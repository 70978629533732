
import { Component,Vue } from 'nuxt-property-decorator';

@Component
export default class IndexSecondViewComponent extends Vue {
    public $refs!: {
        video: HTMLVideoElement;
    };
    public pax = 'images/new-layout/index-images/pax-video.mp4';
    public image:string="images/new-layout/Our-Solutions.png"
    public double_quotes: string ="images/new-layout/Double-quotes.png"
    
    public reviews=[
        {
            title:"The details within Park Sites are incredibly accure!",
            description:`After comparing the park information about my park, and the parks I've worked with potential sellers on, I'm amazed how accurate the park records are in Park Sites. It's definitely my new "go to" tool for researching parks! `,
            img:"images/new-layout/ryan-modified.png",
            name:"Ryan Schroeter",
            company:"National Land Realty"
        },
        {
            title:"Your hard work is so obvious and you should be so so proud!",
            description:"Hey there, I've been meaning to tell you a big fat THANK YOU! for taking the time to go over your website, etc with me a few weeks ago! So, Thank you so much! We are excited to see how it grows! Congratulations on your launch!",
            img:"images/new-layout/kara-modified.png",
            name:"Kara Johnson",
            company:"Johnson Investments"
        },
        {
            title:"I can't wait to see what future updates will bring!",
            description:"I just wanted to drop you a quick note to let you know how much I appreciate your site! I'm always looking for things to add to my toolbox and I think MHPakrs.com will give me the edge I've been looking for!",
            img:"images/new-layout/jodie-modified.png",
            name:"Jodie LaPrell",
            company:""
        },
        {
            title:"The quick response from your support staff is a rarity",
            description:"Your support is awesome! I have never had a site respond to my questions within minutes like you did. Usually I won't hear back for a day or two. It's very apparent you take pride in your site, and want the people using it to have a great experience.",
            img:"images/new-layout/brent-modified.png",
            name:"Brent Denniston",
            company:""
        },
    ]
       public reviewSlider:number = 0;
       public slides = Math.round(this.reviews.length / 2);
       public filteredReviews(index:number){
           return this.reviews.slice(index * 2 ,(index+1)*2)
       }
   
       public reviewSliderSm:number = 0;
       public getAnchorLink(link:string){
           return link.replace(' ','-')
       }
}
