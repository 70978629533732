import { render, staticRenderFns } from "./index.vue?vue&type=template&id=2f4859f2&scoped=true&"
import script from "./index.ts?vue&type=script&lang=ts&"
export * from "./index.ts?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=2f4859f2&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f4859f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromoBannerComponent: require('C:/agent/_work/4/s/components/shared/promo-banner/promo-banner.component.vue').default,IndexFirstViewComponent: require('C:/agent/_work/4/s/components/main-layout-new-design/index/First-view/index-first-view.component.vue').default,IndexFourthViewComponent: require('C:/agent/_work/4/s/components/main-layout-new-design/index/Fourth-view/index-fourth-view.component.vue').default,IndexThirdViewComponent: require('C:/agent/_work/4/s/components/main-layout-new-design/index/Third-view/index-third-view.component.vue').default,IndexSecondViewComponent: require('C:/agent/_work/4/s/components/main-layout-new-design/index/Second-view/index-second-view.component.vue').default})
