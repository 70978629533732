import { render, staticRenderFns } from "./index-second-view.component.vue?vue&type=template&id=48b64b24&scoped=true&"
import script from "./index-second-view.component.ts?vue&type=script&lang=ts&"
export * from "./index-second-view.component.ts?vue&type=script&lang=ts&"
import style0 from "./index-second-view.component.vue?vue&type=style&index=0&id=48b64b24&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b64b24",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PromoCard: require('C:/agent/_work/4/s/components/shared/promo-card/promo-card.vue').default,BaseCard: require('C:/agent/_work/4/s/components/vuetify/base-card/base-card.vue').default})
