import {compareAsc} from 'date-fns';
import {Component, Prop} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';

@Component
export default class PromoBannerComponent extends VueWrapper {
    public fromDate: Date = new Date('9/1/2022');
    public tillDate: Date = new Date('11/30/2022');

    public created() {}

    public get show() {
        const comp = compareAsc(this.tillDate, new Date(new Date().toDateString()));
        // console.log(new Date('9/9/2022'), new Date(new Date().toDateString()), comp);
        return comp !== -1;
    }
}
