import {ClassifiedAdsService} from '@/sdk';
import {FilterModel} from '@/sdk/models/common/filter.model';
import {Component} from 'nuxt-property-decorator';
import VueWrapper from '~/classes/vue.wrapper';

@Component
export default class AdCategoriesSectionComponent extends VueWrapper {
    public items = [
        {
            id: '1',
            image: '/images/home/images/min-parks_for_sale.JPEG',
            subImage: '/images/home/images/Parks for sale.PNG',
            title: 'Parks',
            subTitle: 'for Sale',
            to: 'park-for-sale-ads',
            alt: 'Mobile Home & RV Parks for Sale',
        },
        {
            id: '2',
            image: '/images/home/images/min-mobile_home_for_sale.JPEG',
            subImage: '/images/home/images/mobile home for sale.PNG',
            title: 'Mobile Homes',
            subTitle: 'for Sale',
            category: 'mobile-homes-for-sale',
            to: 'search-classified-classifiedCategory',
            alt: 'Mobile Homes for Sale',
        },
        {
            id: '3',
            image: '/images/home/images/min-rvs_for_sale.JPEG',
            subImage: '/images/home/images/RV.PNG',
            title: "RV's",
            subTitle: 'for Sale',
            category: 'rvs-for-sale',
            to: 'search-classified-classifiedCategory',
            alt: 'RV’s for Sale',
        },
        {
            id: '4',
            image: '/images/home/images/min-brokerage_services.JPEG',
            subImage: '/images/home/images/Brokerge.PNG',
            title: 'Brokerage',
            subTitle: 'Services',
            category: 'mobile-home-park-brokers',
            to: 'search-classified-classifiedCategory',
            alt: 'Real Estate Brokerage Services',
        },
        {
            id: '5',
            image: '/images/home/images/min-lending_services.JPEG',
            subImage: '/images/home/images/Lending.PNG',
            title: 'Lending',
            subTitle: 'Services',
            category: 'mobile-home-park-lenders',
            to: 'search-classified-classifiedCategory',
            alt: 'Mobile Home Park Lending Services',
        },
        {
            id: '6',
            image: '/images/home/images/min-insurance_services.JPEG',
            subImage: '/images/home/images/insurance.PNG',
            title: 'Insurance',
            subTitle: 'Services',
            category: 'mobile-home-park-insurance-agents',
            to: 'search-classified-classifiedCategory',
            alt: 'Mobile Home & RV Park Insurance Services',
        },
        {
            id: '7',
            image: '/images/home/images/min-coaching_services.JPEG',
            subImage: '/images/home/images/coaching.PNG',
            title: 'Coaching',
            subTitle: 'Services',
            category: 'mobile-home-park-investment-coach',
            to: 'search-classified-classifiedCategory',
            alt: 'Mobile Home Park Investment Coaching Services',
        },
        {
            id: '8',
            image: '/images/home/images/min-ISO.JPEG',
            subImage: '/images/home/images/In Search.PNG',
            title: 'In ',
            subTitle: 'Search Of',
            category: 'mobile-home-park-in-search-of',
            to: 'search-classified-classifiedCategory',
            alt: 'In Search of All Things Related to Mobile Home & RV Parks',
        },
    ];

    // public AdByCategory(categoryId: string) {
    //     const filter = new FilterModel();
    //     filter.Status = categoryId;
    //     this.classifiedSrv.GetAdsByCategory(filter);
    // }

    public onAdCategoryClick(item: any) {
        if (item.to) {
            this.$router.push({name: item.to});
        }
        if (item.category) {
            this.$router.push({name: 'SearchClassified', params: {classifiedCategory: item.category}});
        }
    }
}
