import https from 'https';
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {Component, Vue} from 'nuxt-property-decorator';
import {catchError, map, Observable, Subscriber, throwError} from 'rxjs';
import {AlertService, ClassifiedAdModel, ClassifiedApi, LoaderService, ParkListingApi} from '~/sdk';
import {ParkLisitingViewModel} from '~/sdk/models/park-listing';
import {AdminDashboardApi} from '~/sdk/api-services/admin-dashboard/admin-dashboard.api';
import VueWrapper from '~/classes/vue.wrapper';
import {FilterModel} from '~/sdk/models/common/filter.model';
import {mhParksAddress, PageHitsType, mhParksContactPoint} from '~/globals';
import ContactSellerComponent from '~/shared/contact-seller/contact-seller.component';
import AdCategoriesSectionComponent from '~/shared/advertise/ad-categories-section/ad-categories-section.component';
import {SdkConfig} from '~/sdk/sdk.config';
import IndexFirstViewComponent from '~/components/main-layout-new-design/index/First-view/index-first-view.component';
import IndexSecondViewComponent from '~/components/main-layout-new-design/index/Second-view/index-second-view.component';
import IndexThirdViewComponent from '~/components/main-layout-new-design/index/Third-view/index-third-view.component';
import BaseDividerComponent from '~/components/shared/base-divider/base-divider.component';
import IndexFourthViewComponent from '~/components/main-layout-new-design/index/Fourth-view/index-fourth-view.component';
@Component<Index>({
    layout: 'main-layout.component',
    scrollToTop: true,
    components: {
        ContactSellerComponent,
        AdCategoriesSectionComponent,
        IndexFirstViewComponent,
        IndexSecondViewComponent,
        IndexThirdViewComponent,
        IndexFourthViewComponent,
        'base-divider': BaseDividerComponent,
    },
    head() {
        return {
            title: 'Serving People In the MH/RV Park Industry | MHParks.com',
            meta: [
                {
                    name: 'description',
                    content:
                        'Our mission is to be a central platform for people in the MH/RV park industry to research, advertise, and network using our solutions.',
                },
                {
                    name: 'keywords',
                    content:
                        'mobile home, mobile home park, mh/rv park, solutions, pax, data center, park analytics exchange, park records, park sites, advertise, contacts crm, sold parks, my parks, professionals',
                },
                {name: 'og:type', content: 'website', hid: 'og:type'},
                {name: 'og:url', content: '/', hid: 'og:url'},
                {name: 'og:title', content: 'Serving People in the MH/RV Park Industry | MHParks.com', hid: 'og:title'},
                {
                    name: 'og:description',
                    content:
                        'Discover our solutions that help Pax to learn about mobile home and RV park information throughout the United States. Create a free account today!',
                    hid: 'og:description',
                },
                {property: 'og:image', content: '/images/meta/meta_home-min.png', hid: 'og:image'},
                {name: 'twitter:card', content: 'summary_large_image', hid: 'twitter:card'},
                {name: 'twitter:url', content: '/', hid: 'twitter:url'},
                {name: 'twitter:title', content: 'Serving People in the MH/RV Park Industry | MHParks.com', hid: 'twitter:title'},
                {
                    name: 'twitter:description',
                    content:
                        'Discover our solutions that help Pax to learn about mobile home and RV park information throughout the United States. Create a free account today!',
                    hid: 'twitter:description',
                },
                {name: 'twitter:image', content: '/images/meta/meta_home-min.png', hid: 'twitter:image'},
            ],
            script: [{innerHTML: JSON.stringify(this.scriptSchema), type: 'application/ld+json'}],
        };
    },
})
export default class Index extends Vue {
    public title: string = 'This is page title';
    public scriptSchema: Array<any> = [
        {
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: 'MHParks.com',
            url: 'https://www.mhparks.com',
            logo: 'https://www.mhparks.com/images/mainLogo2.png',
            telephone: '402-615-9165',
            email: 'support@mhparks.com',
        },
        {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            name: 'MHParks.com',
            url: 'https://www.mhparks.com/',
        },
        {
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'MH/RV Park Data Driven By Solutions | MHParks.com',
            image: ['https://www.mhparks.com/images/new-layout/mobile_home_park.png'],
            url: 'https://www.mhparks.com',
            description:
                'Discover our solutions that help Pax to learn about mobile home and RV park information throughout the United States. Create a free account today!',
        },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     name: 'Park Sites Solution',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/Park-sites-Blue-min.png'],
        //     url: 'https://www.mhparks.com/solutions/park-sites-solution',
        //     description:
        //         "Discover all of Pax's MH/RV park knowledge with Park Sites! Create your free forever Basic account to get started using our solutions today.",
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Ryan Schroeter',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '4.8',
        //             bestRating: '5',
        //         },
        //     },
        // },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     name: 'Advertise Solution',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/advertise-solution-new.png'],
        //     url: 'https://www.mhparks.com/advertise-solution',
        //     description:
        //         'Advertise your MH/RV park for sale with our Advertise solution!  Create your free forever Basic account to get started using our solutions today.',
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Ryan Schroeter',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '4.7',
        //             bestRating: '5',
        //         },
        //     },
        // },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     name: 'Contacts Solutions',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/contacts-crm-solution.png'],
        //     url: 'https://www.mhparks.com/solutions/contacts-crm-solution',
        //     description:
        //         'Store all of your MH/RV park industry contacts in one place! Create your free forever Basic account to get started using our solutions today.',
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Jodie LaPrell',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '4.0',
        //             bestRating: '5',
        //         },
        //     },
        // },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     name: 'Sold Parks Solution',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/sold-parks-solution-min.png'],
        //     url: 'https://www.mhparks.com/solutions/sold-parks-solution',
        //     description: 'Search for MH/RV park comparable sales! Create your free forever Basic account to get started using our solutions today.',
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Brent Denniston',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '3.8',
        //             bestRating: '5',
        //         },
        //     },
        // },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     name: 'My Parks Solution',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/my-parks-solution-min.png'],
        //     url: 'https://www.mhparks.com/solutions/my-parks-solution',
        //     description:
        //         'Showcase the parks you own in our My Parks solution. Create your free forever Basic account to get started using our solutions today.',
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Brent Denniston',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '4.5',
        //             bestRating: '5',
        //         },
        //     },
        // },
        // {
        //     '@context': 'https://schema.org/',
        //     '@type': 'Product',
        //     image: ['https://www.mhparks.com/images/new-layout/pax-images/professionals-solution-min.png'],
        //     name: 'Professionals Solution',
        //     url: 'https://www.mhparks.com/solutions/professionals-solution',
        //     description:
        //         'Get recognized in the MH/RV park industry with our Professionals solution! Create your free forever Basic account to get started today.',
        //     review: {
        //         '@type': 'Review',
        //         author: {
        //             '@type': 'Person',
        //             name: 'Ryan Schroeter',
        //         },
        //         reviewRating: {
        //             '@type': 'Rating',
        //             ratingValue: '4.7',
        //             bestRating: '5',
        //         },
        //     },
        // },

        // {
        //     "@context": "https://schema.org",
        //     "@type": "Product",
        //     "name": "Receive a Free Pax Solutions Report | MHParks.com",
        //     "image": ["https://www.mhparks.com/pageImageOfPax.jpg"],
        //     "url": "https://www.mhparks.com/solutions",
        //     "description": "Request your free Pax Solutions Report to learn how our solutions can help you in the MH/RV park industry. No account required!",
        //     "review": {
        //     "@type": "Review",
        //     "author":{
        //             "@type": "Person",
        //             "name": "Gil Wigington"
        //         },
        //     "reviewRating": {
        //         "@type": "Rating",
        //         "ratingValue": "4.2",
        //         "bestRating": "5"
        //     }}
        // },
    ];

    public data: any = {};

    public async fetch() {
        const agent = new https.Agent({
            rejectUnauthorized: false,
        });

        (this as any).$axios.onRequest((config: any) => {
            config.httpsAgent = agent;
        });
    }

    public signUp: boolean = true;
    public showDisplay = this.$vuetify.breakpoint.name === 'lg' || this.$vuetify.breakpoint.name === 'md' || this.$vuetify.breakpoint.name === 'xl';
    public featuredClassifiedAds: Array<ClassifiedAdModel> = [];
    public featuredParkAds: Array<ParkLisitingViewModel> = [];
    public classifiedApi = new ClassifiedApi();
    public adminDashboardApi = new AdminDashboardApi();
    public parksAvailabilityNo: Array<{title: string; icon: string; text: string; alt: string}> = [
        {title: 'SEO', icon: 'mobile-home-park-seo.svg', text: 'SEO that’s friendly with Google', alt: 'Mobile Home Park SEO'},
        {title: 'Leads', icon: 'mobile-home-park-leads.svg', text: 'Generate new leads to network', alt: 'Mobile Home Park Leads'},
        {
            title: 'Savings',
            icon: 'mobile-home-park-brokers-discount.svg',
            text: 'Publish an unlimited number of ads free',
            alt: 'Mobile Home Park Broker Discount',
        },
        {title: 'Records', icon: 'mobile-home-park-records.svg', text: '30,000 operational MH/RV park records', alt: 'Mobile Home Park Integration'},
    ];

    public inneriTems = [
        {
            id: '1',
            image: '/images/home/images/Parks for sale.PNG',
            description: 'Parks for Sale',
        },
        {
            id: '2',
            image: '/images/home/images/mobile home for sale.PNG',
            description: 'Mobile Homes for Sale',
        },
        {
            id: '3',
            image: '/images/home/images/RV.PNG',
            description: "RV's for Sale",
        },
        {
            id: '4',
            image: '/images/home/images/Brokerge.PNG',
            description: 'Brokerage Services',
        },
        {
            id: '5',
            image: '/images/home/images/Lending.PNG',
            description: 'Lending Services',
        },
        {
            id: '6',
            image: '/images/home/images/insurance.PNG',
            description: 'Insurance Services',
        },
        {
            id: '7',
            image: '/images/home/images/coaching.PNG',
            description: 'Coaching Services',
        },
        {
            id: '8',
            image: '/images/home/images/In Search.PNG',
            description: 'In Search Of',
        },
    ];
    public items2 = [
        {
            id: '1',
            title: '/images/laptop.png',
            description: 'Park for Sale',
        },
        {
            id: '2',
            title: '/images/laptop.png',
            description: 'Mobile Home for Sale',
        },
        {
            id: '3',
            title: '/images/laptop.png',
            description: 'Brokerage Services',
        },
        {
            id: '4',
            title: '/images/laptop.png',
            description: 'Lending Services',
        },
        {
            id: '5',
            title: '/images/laptop.png',
            description: 'Insurance Services',
        },
        {
            id: '6',
            title: '/images/laptop.png',
            description: 'Coaching Services',
        },
        {
            id: '7',
            title: '/images/laptop.png',
            description: "RV's for Sale",
        },
        {id: '8', title: '/images/laptop.png', description: 'In Search Of'},
    ];
    public items3 = [
        {
            id: '1',
            title: '/images/laptop.png',
            description: 'Park for Sale',
        },
        {
            id: '2',
            title: '/images/laptop.png',
            description: 'Mobile Home for Sale',
        },
        {
            id: '3',
            title: '/images/laptop.png',
            description: 'Brokerage Services',
        },
        {
            id: '4',
            title: '/images/laptop.png',
            description: 'Lending Services',
        },
        {
            id: '5',
            title: '/images/laptop.png',
            description: 'Insurance Services',
        },
        {
            id: '6',
            title: '/images/laptop.png',
            description: 'Coaching Services',
        },
        {
            id: '7',
            title: '/images/laptop.png',
            description: "RV's for Sale",
        },
        {id: '8', title: '/images/laptop.png', description: 'In Search Of'},
    ];
    get maxHeight() {
        if (this.$vuetify.breakpoint.name === 'xs') {
            return 500;
        } else return 400;
    }

    get image() {
        if (this.$vuetify.breakpoint.name === 'xs') {
            return '/images/Hero/images/home-small.jpg';
        } else return '/images/Hero/images/home.jpg';
    }
    get height() {
        if (this.$vuetify.breakpoint.name === 'xs') {
            return null;
        } else if (this.$vuetify.breakpoint.name === 'sm') {
            return null;
        } else return 350;
    }

    public get ApiBaseUrl() {
        return SdkConfig.ApiBaseUrl;
    }

    public displayText = 'Your account has now been verified. Please login.';
    public mounted() {
        if (this.$route.query.verified === 'true') {
            new AlertService()
                .show(
                    'success',
                    // `${this.displayText.replace(/#1/, this.countDown)}`
                    `${this.$route.query.message}`
                )
                .then(() => {
                    // this.CoreSrv.dialog.loginsignupPage = true;
                    this.$router.push({name: 'login'});
                });
            // var closeInSeconds = 5,
            //     timer: any;
            // Swal.fire({
            //     title: 'Auto close alert!',
            //     text: displayText.replace(/#1/, this.closeInSeconds),
            //     timer: closeInSeconds * 1000,
            //     showConfirmButton: false
            // });
            // timer = setInterval(function() {
            //     closeInSeconds--;
            //     if (closeInSeconds < 0) {
            //         clearInterval(timer);
            //     }
            //     $('.sweet-alert > p').text(
            //         displayText.replace(/#1/, closeInSeconds)
            //     );
            // }, 1000);
        } else if (this.$route.query.verified === 'false') {
            new AlertService().show('error', (this.$route.query.message as string) ?? 'Error occured.');
        }
        if (this.$route.query.type === 'ClaimDenied' || this.$route.query.type === 'ClaimGranted') {
            new AlertService().show('success', (this.$route.query.message as string) ?? 'No message returned.').then(() => {});
        }
        if (this.$route.query.type === 'ClaimException') {
            new AlertService().show('warning', (this.$route.query.message as string) ?? 'No message returned.').then(() => {});
        }
        if (this.$route.query.type === 'InvalidUrl') {
            new AlertService().show('warning', (this.$route.query.message as string) ?? 'No message returned.');
        }
        if (['Granted', 'Denied'].indexOf(`${this.$route.query.AdvertisePermmission}`) !== -1) {
            new AlertService().show('success', (this.$route.query.message as string) ?? 'No message returned.').then(() => {});
        } else if (this.$route.query.AdvertisePermmission === 'Error') {
            new AlertService().show('warning', (this.$route.query.message as string) ?? 'No message returned.');
        }
        if (!!this.$route.query.message) {
            this.$router.push({name: 'index'});
        }
        // this.countDownTimer();
        // this.GetFeaturedClassifiedAds();
        // this.GetFeaturedParkAds();

        // if (process.client) {
        //     this.PageHits();
        // }
    }

    // public GetFeaturedClassifiedAds() {
    //     return new Promise(resolve => {
    //         new LoaderService().showFullScreenLoader();
    //         this.classifiedApi
    //             .GetFeaturedClassifiedAds(
    //                 new FilterModel({
    //                     PageNo: 1,
    //                     PageSize: 8,
    //                 })
    //             )
    //             .subscribe(
    //                 ({Data, Status}) => {
    //                     if (Status) {
    //                         this.featuredClassifiedAds.splice(0, this.featuredClassifiedAds.length);
    //                         this.featuredClassifiedAds.push(...(Data?.Data || []));
    //                     }
    //                 },
    //                 err => {}
    //             )
    //             .add(() => {
    //                 new LoaderService().hideFullScreenLoader();
    //                 resolve(null);
    //             });
    //     });
    // }
    // public GetFeaturedParkAds() {
    //     return new Promise(resolve => {
    //         new LoaderService().showFullScreenLoader();
    //         new ParkListingApi()
    //             .GetFeautedParkListings(
    //                 new FilterModel({
    //                     PageSize: 8,
    //                     PageNo: 1,
    //                 })
    //             )
    //             .subscribe(
    //                 ({Data, Status}) => {
    //                     // console.log(Data);
    //                     if (Status) {
    //                         this.featuredParkAds.splice(0, this.featuredParkAds.length);
    //                         this.featuredParkAds.push(...(Data?.Data || []));
    //                     }

    //                     resolve(null);
    //                 },
    //                 err => {
    //                     resolve(null);
    //                 }
    //             )
    //             .add(() => {
    //                 new LoaderService().hideFullScreenLoader();
    //             });
    //     });
    // }

    // public countDown: number = 60;
    // public countDownTimer() {
    //     if (this.countDown > 0) {
    //         setTimeout(() => {
    //             this.countDown -= 1;
    //             this.countDownTimer();
    //         }, 1000);
    //     }
    // }

    public PageHits() {
        new AdminDashboardApi().PageHits(PageHitsType.HomePage).subscribe(
            () => {},
            err => {}
        );
    }
}
