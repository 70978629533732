import { Component,Vue } from 'nuxt-property-decorator';

@Component
export default class IndexFourthViewComponent extends Vue {
    public image: string = 'images/new-layout/Our-Solutions.png';
    public double_quotes: string = 'images/new-layout/Double-quotes.png';
    public items = [
        {
            title: 'Park For Sale Ads',
            description: 'View MH/RV Park for Sale Ads that our members have published while using our Advertise solution',
            img: '/images/new-layout/pricing-images/handSpeaker.png',
            link: 'park-for-sale-ads',
            alt: 'Park For Sale Ads',
        },
        {
            title: 'Classified Ads',
            description: 'View MH/RV Park Classified Ads published by our members while using our Advertise solution',
            img: '/images/new-layout/pricing-images/globeSearch.png',
            link: 'classified-ads',
            alt: 'Classified Ads',
        },
        {
            title: 'Professionals',
            description: 'View Professionals serving the MH/RV park industry that are using our Professionals solution',
            img: '/images/new-layout/pricing-images/securityUser.png',
            link: 'professionals',
            alt: 'Classified Ads',
        },
    ];
    public Solutions = [
        {
            title: 'Park Sites',
            src: 'images/new-layout/park-sites-solution-white-min.png',
            desc: 'You work, Pax learns! Use his tools to discover, research, and filter Pax’s latest knowledge of 30,000 operational mobile home and RV parks.',
            name: 'park-for-sale-ads',
            more: 'Explore Park Sites',
            link: '/solutions/park-sites-solution',
            alt: 'Park Sites Solution',
        },
        {
            title: 'Advertise',
            src: 'images/new-layout/advertise-solution-white-min.png',
            desc: 'Advertise your park mobile home park listings or services with a Park for Sale Ad or Classified Ad. With each Park for Sale Ad, Pax learns a bit more.',
            name: 'classified-ads',
            more: 'Explore Advertise',
            link: '/solutions/advertise-solution',
            alt: 'Advertise Solution',
        },
        {
            title: 'Contacts',
            src: 'images/new-layout/contacts-crm-solution-white-min.png',
            desc: 'Store all of your MH/RV park industry contacts in one place while you discover even more details about the professionals within the industry.',
            name: 'contact-us',
            more: 'Explore Contacts CRM',
            link: '/solutions/contacts-crm-solution',
            alt: 'Contacts CRM Solution',
        },
        {
            title: 'Sold Parks',
            src: 'images/new-layout/sold-parks-solution-white-min.png',
            desc: 'Search for mobile home park and RV park comparable sales that our members have submitted after they successfully sold their park with a Park for Sale Ad.',
            name: 'sold-parks-solution',
            more: 'Explore Sold Parks',
            link: '/solutions/sold-parks-solution',
            alt: 'Sold Parks Solution',
        },
        {
            title: 'My Parks',
            src: 'images/new-layout/my-parks-solution-white-min.png',
            desc: "Showcase the mobile home parks or RV parks you own by claiming them in our My Parks solution. After you've claimed your park, Pax will brand them as yours within Park Sites.",
            name: 'my-parks-solution',
            more: 'Explore My Parks',
            link: '/solutions/my-parks-solution',
            alt: 'My Parks Solution',
        },
        {
            title: 'Professionals',
            src: 'images/new-layout/professionals-solution-white-min.png',
            desc: 'Promote yourself to others in the mobile home park industry by opting in from within your profile. This tells Pax to display your information on our public Professionals page.',
            name: 'professionals',
            more: 'Explore Professionals',
            link: '/solutions/professionals-solution',
            alt: 'Professionals Solution',
        },
    ];

    public reviews = [
        {
            title: 'The details within Park Sites are incredibly accure!',
            description: `After comparing the park information about my park, and the parks I've worked with potential sellers on, I'm amazed how accurate the park records are in Park Sites. It's definitely my new "go to" tool for researching parks! `,
            img: 'images/new-layout/ryan-modified.png',
            name: 'Ryan Schroeter',
            company: 'National Land Realty',
        },
        {
            title: 'Your hard work is so obvious and you should be so so proud!',
            description:
                "Hey there, I've been meaning to tell you a big fat THANK YOU! for taking the time to go over your website, etc with me a few weeks ago! So, Thank you so much! We are excited to see how it grows! Congratulations on your launch!",
            img: 'images/new-layout/kara-modified.png',
            name: 'Kara Johnson',
            company: 'Johnson Investments',
        },
        {
            title: "I can't wait to see what future updates will bring!",
            description:
                "I just wanted to drop you a quick note to let you know how much I appreciate your site! I'm always looking for things to add to my toolbox and I think MHPakrs.com will give me the edge I've been looking for!",
            img: 'images/new-layout/jodie-modified.png',
            name: 'Jodie LaPrell',
            company: '',
        },
        {
            title: 'The quick response from your support staff is a rarity',
            description:
                "Your support is awesome! I have never had a site respond to my questions within minutes like you did. Usually I won't hear back for a day or two. It's very apparent you take pride in your site, and want the people using it to have a great experience.",
            img: 'images/new-layout/brent-modified.png',
            name: 'Brent Denniston',
            company: '',
        },
    ];
    public reviewSlider: number = 0;
    public slides = Math.round(this.reviews.length / 2);
    public filteredReviews(index: number) {
        return this.reviews.slice(index * 2, (index + 1) * 2);
    }

    public reviewSliderSm: number = 0;
    // public scrollMeTo(refName:string) {
    //     console.log(this.$refs[refName.split(' ').join('-')])
    //     this.$router.push({name:'solutions'})
    //     const element:any = this.$refs[refName.split(' ').join('-')];
    //     const top = element.offsetTop;

    //     window.scrollTo(0, top);
    //   }
    // public getAnchorLink(link:string){
    //     // if(link == )
    //     return link.replace(' ','-')
    // }
    // scrollToBottom(){
    //     this.$refs['bottom'].scrollIntoView({behavior: "smooth"})
    //   }
}