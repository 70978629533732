import { render, staticRenderFns } from "./index-first-view.component.vue?vue&type=template&id=a7bd339e&scoped=true&"
import script from "./index-first-view.component.ts?vue&type=script&lang=ts&"
export * from "./index-first-view.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7bd339e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MhParksBtn: require('C:/agent/_work/4/s/components/base/mh-parks-btn/mh-parks-btn.vue').default,PaxIntroCard: require('C:/agent/_work/4/s/components/base/pax-intro-card/pax-intro-card.vue').default})
