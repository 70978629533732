import { render, staticRenderFns } from "./ad-categories-section.component.vue?vue&type=template&id=f23ea070&"
import script from "./ad-categories-section.component.ts?vue&type=script&lang=ts&"
export * from "./ad-categories-section.component.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseCard: require('C:/agent/_work/4/s/components/vuetify/base-card/base-card.vue').default})
