import {Component, Vue} from 'nuxt-property-decorator';
@Component
export default class IndexFirstViewComponent extends Vue {
    public titleSlides = [
        'Park Investors',
        'Park Brokers',
        'Park Wholesaler',
        'Park Owners',
        'Insurance Agents',
        'Lending Agents',
        'Park Managers',
        'Park Coaches',
        'MH Dealers',
        'RV Dealers',
    ];

    public items = [
        {
            title: 'Blog Articles',
            img: '/images/svg/blog.svg',
            link: 'blog',
            alt: 'Blog Articles',
        },
        {
            title: 'Pricing',
            img: '/images/svg/pricing.svg',
            link: 'pricing',
            alt: 'Pricing',
        },
        {
            title: 'Help Center',
            img: '/images/svg/help-center.svg',
            link: 'https://help.mhparks.com',
            alt: 'Help Center',
        },
        // {
        //     title: 'Professionals',
        //     img: '/images/new-layout/user-icon.png',
        //     link: 'professionals',
        // },
        // {
        //     title: 'Classified Ads',
        //     img: '/images/new-layout/search-icon.png',
        //     link: 'classified-ads',
        // },
        // {
        //     title: 'Park For Sale Ads',
        //     img: '/images/new-layout/speaker-icon.png',
        //     link: 'park-for-sale-ads',
        // },
    ];
}
