import {PageHitsType} from '@/globals';
import {AdminDashboardGraphViewModel, AdminDashboardViewModel} from '@/sdk/models';
import {PageHitsModel} from '@/sdk/models/admin-dashboard/page-hits.model';

import {BaseApi} from '../base.api';

export class AdminDashboardApi extends BaseApi {
    public WidgetsData() {
        return this.GET_Request<AdminDashboardViewModel>(`${this.ApiUrl}/AdminDashboard/WidgetsData`);
    }

    public GraphData(months: number) {
        return this.GET_Request<AdminDashboardGraphViewModel>(`${this.ApiUrl}/AdminDashboard/GraphData/${months}`);
    }

    public PageHits(type: PageHitsType) {
        return this.GET_Request<PageHitsModel>(`${this.ApiUrl}/AdminDashboard/UpdateHomepageCounter/${type}`);
    }
}
